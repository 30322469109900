import {Injectable} from '@angular/core';
import {arrayify, AuthActionTypes, ElementOrArray} from '@forlabs/api-bridge';
import {EntityActionFactory, EntityActionPayload, EntityOp} from '@ngrx/data';
import {EntityAction} from '@ngrx/data/src/actions/entity-action';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {combineLatestWith, from, map, of, switchMap, timer} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Article} from '../articles/articles.models';
import {CourseMessage} from '../course-messages/course-messages.models';
import {Info} from '../infos/infos.models';
import {PatientStep} from '../patient-steps/patient-steps.models';
import {MercureService} from '../../infrastructure/mercure.service';
import {Contact, Patient, User} from '../users/users.models';
import {CurrentUserService} from '../../infrastructure/current-user.service';
import {getAllPatients, loadPatientByID} from '../../ngrx/patients/patients.actions';
import {
  isContact, isArticle,
  isInfo, isPatient, isPatientStep,
  mercureActionConnectSuccess, mercureActionDisconnect,
  mercureActionProcessEvent,
  mercureActionSetJwt, MercureEvent, isCourseMessage, mercureActionConnect, mercureActionScheduleReconnect,
} from './actions';
import {getStatus} from './selectors';


declare const window: any;

@Injectable()
export class MercureEffects {
  private user: User = null;

  public getJwtOnLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    switchMap(_ => {
      return this.currentUserService.currentUser$.pipe(
        filter(Boolean),
        map(user => mercureActionSetJwt({jwt: user.mercureToken})),
      );
    }),
  ));

  public getJwt$ = createEffect(() => this.actions$.pipe(
    ofType(mercureActionSetJwt),
    switchMap(({jwt}) => {
      return of(mercureActionConnect({jwt}));
    }),
  ));

  public connectToMercure$ = createEffect(() => this.actions$.pipe(
    ofType(mercureActionConnect),
    combineLatestWith(this.store.select(getStatus)),
    filter(([_, status]) => status === 'ready' || status == 'connected'),
    switchMap(([{jwt}, _]) => {
      if (this.mercureService.startEventSource(jwt)) {
        return of(mercureActionConnectSuccess());
      } else {
        return of(mercureActionScheduleReconnect({jwt: jwt}));
      }
    }),
  ));

  public reconnectToMercure$ = createEffect(() => this.actions$.pipe(
    ofType(mercureActionScheduleReconnect),
    switchMap(jwt => {
      return timer(15000).pipe(map(_ => mercureActionConnect(jwt)));
    }),
  ));

  public disconnectFromMercure$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionTypes.LOGOUT),
    switchMap(_ => {
      this.mercureService.stopEventSource();

      // FIXME: this is a hack to force a reload of the application
      // because dataservice.all$ store emitters are not repopulated after logout/relogin
      window.location.reload();
      return of(mercureActionDisconnect());
    }),
  ));

  public processEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mercureActionProcessEvent),
      map(({event}): ElementOrArray<EntityAction<MercureEvent|string>> => {
        if (isCourseMessage(event)) {
          return this.actionFactory.create(this.getActionForEvent(event));
        }

        if (isPatientStep(event)) {
          const commands: ElementOrArray<EntityAction<MercureEvent|string>> = [
            this.actionFactory.create(this.getActionForEvent(event)),
          ];

          // Trigger a reload of patients on patient step creation (or else it wont show up in admin list).
          // It *should* be a creation if the history has only a single item.
          if (event.history.length < 2) {
            commands.push(this.actionFactory.create({
              entityName: Patient.getEntityName(),
              entityOp: EntityOp.QUERY_BY_KEY,
              data: event.patientIri,
            }));
          }

          return commands;
        }

        if (isInfo(event)) {
          return this.actionFactory.create(this.getActionForEvent(event));
        }

        if (isArticle(event)) {
          return this.actionFactory.create(this.getActionForEvent(event));
        }

        if (isPatient(event)) {
          const ret: ElementOrArray<EntityAction<MercureEvent|string>> = [
            this.actionFactory.create(this.getActionForEvent(event)),
          ];

          if (this.user instanceof Patient) {
            // Reload all steps as they might have changed
            const reloadStepsAction = this.actionFactory.create({
              entityName: PatientStep.getEntityName(),
              entityOp: EntityOp.QUERY_ALL,
            });
            ret.push(reloadStepsAction);
          }

          // reload new patient store
          this.store.dispatch(getAllPatients({loader: false}));

          return ret;
        }

        if (isContact(event)) {
          // trigger reloads to to get updated informations
          const ret: ElementOrArray<EntityAction<MercureEvent|string>> = [];

          // Reload all steps and related users as some may have move while we were "offline"
          event.patients?.forEach(patient => {
            console.log('isContact');
            this.store.dispatch(loadPatientByID({id: patient.id}));

            ret.push(this.actionFactory.create({
              entityName: Patient.getEntityName(),
              entityOp: EntityOp.QUERY_BY_KEY,
              // data: '/api/patients/'+this.user.patients[0].id,
              data: patient['@id'],
            }));
          });

          // Reload the contact itself, to retrieve updated contact information
          // We do this because if patient is updated, we receive an empty contact
          ret.push(this.actionFactory.create({
            entityName: Contact.getEntityName(),
            entityOp: EntityOp.QUERY_BY_KEY,
            data: event['@id'],
          }));

          if (this.user instanceof Contact) {
            // If current user is a contact, reload all steps as they might have changed (while flow was disabled)
            const reloadStepsAction = this.actionFactory.create({
              entityName: PatientStep.getEntityName(),
              entityOp: EntityOp.QUERY_ALL,
            });

            ret.push(reloadStepsAction);
          }

          return ret;
        }

        console.error('UNKNOWN EVENT TYPE', event);
        throw new Error('Unknown event type');
      }),
      map(elementOrArray => arrayify<EntityAction<MercureEvent|string>>(elementOrArray)),
      switchMap(actions => from(actions)),
    );
  });

  private isDeletionEvent(event: MercureEvent): boolean {
    // If the event only contains @id and @type, it's a deletion event
    // @see https://api-platform.com/docs/core/mercure/
    for (const prop in event) {
      if (prop !== '@id' && prop !== '@type') {
        return false;
      }
    }
    return true;
  }

  private getActionForEvent(event: MercureEvent): EntityActionPayload {
    if (this.isDeletionEvent(event)) {
      return {
        entityName: this.getEntityNameForEvent(event),
        entityOp: EntityOp.REMOVE_ONE,
        data: event['@id'],
      };
    }

    return {
      entityName: this.getEntityNameForEvent(event),
      entityOp: EntityOp.UPSERT_ONE,
      data: event,
    };
  }

  private getEntityNameForEvent(event: MercureEvent): string {
    switch (true) {
      case isCourseMessage(event):
        return CourseMessage.getEntityName();
      case isPatientStep(event):
        return PatientStep.getEntityName();
      case isInfo(event):
        return Info.getEntityName();
      case isPatient(event):
        return Patient.getEntityName();
      case isContact(event):
        return Contact.getEntityName();
      case isArticle(event):
        return Article.getEntityName();
      default:
        throw new Error('Unknown event type ' + event['@type']);
    }
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly mercureService: MercureService,
    private readonly actionFactory: EntityActionFactory,
    private readonly currentUserService: CurrentUserService,
  ) {
    currentUserService.currentUser$.subscribe(user => this.user = user);
  }
}
