import {EntityBuilder} from '@decahedron/entity';
import {UserInterface as BaseUserInterface} from '@forlabs/api-bridge';
import {differenceInYears} from 'date-fns';
import {map, Observable, pipe, UnaryFunction} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AbstractFollowMeEntity, FollowMeEntityInterface} from '../entity';
import {Info, InfoInterface} from '../infos/infos.models';
import {Organization, OrganizationInterface} from '../organizations/organizations.models';
import {PatientStep, PatientStepInterface} from '../patient-steps/patient-steps.models';


export const ROLES = [
  'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_HEALTHPRO_EXECUTIVE', 'ROLE_HEALTHPRO', 'ROLE_PATIENT', 'ROLE_CONTACT',
] as const;
export type Role = (typeof ROLES)[number];
export const ROLE_LABELS: Record<Role, string> = {
  ROLE_ADMIN: 'Rôle administrateur',
  ROLE_HEALTHPRO_EXECUTIVE: 'Rôle professionnel executif',
  ROLE_HEALTHPRO: 'Rôle professionnel',
  ROLE_MANAGER: 'Rôle manager',
  ROLE_PATIENT: 'Rôle patient',
  ROLE_CONTACT: 'Rôle contact',
};

export const EXIT_TYPES = ['retour', 'hospit', 'transfert', 'deceased', 'runaway'] as const;
export type ExitType = typeof EXIT_TYPES[number];

// ------------------------------------------------------------
// USER
// ------------------------------------------------------------

export interface UserInterface extends FollowMeEntityInterface {
  firstName: string;
  lastName: string;
  email: string;
  login: string;
  phone: string;
  role: Role;
  passwordDefault: boolean;
  organization?: OrganizationInterface;
  organizationIri: string;
  plainPassword?: string; // At creation or edition only, set client-side, sent to the API
  currentPassword?: string; // At edition only, set client-side, sent to the API
  activatedAt: Date;
  mercureToken: string;
  createdAt: Date;
  isDelete: boolean;
  
  getRoles(): string[];
}

export class User extends AbstractFollowMeEntity implements UserInterface, BaseUserInterface {
  public firstName: string = null;
  public lastName: string = null;
  public email: string = null;
  public phone: string = null;
  public login: string = null;
  public role: Role = null;
  public passwordDefault: boolean = null;
  public organization: Organization = null;
  public organizationIri: string = null;
  public plainPassword?: string; // At creation or edition only, set client-side, sent to the API
  public currentPassword?: string; // At edition only, set client-side, sent to the API
  public createdAt: Date = null;
  public activatedAt: Date = null;
  public mercureToken: string = null;
  public isDelete: boolean = null;

  public static override getEntityName(): 'user' | 'patient' | 'contact' | 'health_pro' | 'admin' {
    return 'user';
  }

  public override fromJson(jsonData: Record<string, unknown>): this {
    super.fromJson(jsonData);
    if (typeof this.activatedAt === 'string') {
      this.activatedAt = new Date(this.activatedAt);
    }
    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }
    return this;
  }

  public get fullName(): string {
    return `${this.lastName.toLocaleUpperCase()} ${this.firstName}`;
  }

  public getRoles(): string[] {
    return [this.role];
  }
}

// ------------------------------------------------------------
// PATIENT
// ------------------------------------------------------------

export const PATIENT_ERROR_FIELDS = [
  'email', 'phone', 'firstName', 'lastName', 'address', 'city', 'zip', 'birthDate',
] as const satisfies readonly (keyof PatientInterface)[];
export type PatientErrorField = typeof PATIENT_ERROR_FIELDS[number];

export type PatientStatus =
  | 'initial'
  | 'waiting'
  | 'nurse_done_and_emergency_doctor_waiting'
  | 'emergency_doctor_done_and_nurse_waiting'
  | 'both_done';

export type ExitStatus =
  | 'initial'
  | 'planned'
  | 'bed_found'
  | 'effective'
  | 'runaway'
  | 'deceased';

export type PatientHistoryEntry = {
  date: Date;
  status: string;
  transition: string;
} & (
  {
    type: 'patient';
  }
  | {
    type: 'exit';
    exit_type: ExitType;
    exit_destination_id: string;
  }
);

export interface PatientInterface extends UserInterface {
  // socialSecurityNumber: string;
  birthDate: string;
  address: string;
  zip: string;
  city: string;
  // generalPractitioner: string;
  zone: string;
  flowEnabled: boolean;
  acceptedCgv: boolean;
  errors: PatientErrorField[];
  orientationStatus: PatientStatus;
  exitStatus: ExitStatus;
  exitType: ExitType;
  exitDestinationId: string;
  lastInfo?: InfoInterface;
  lastInfoIri: string;
  contacts?: ContactInterface[];
  contactsIris: string[];
  steps?: PatientStepInterface[];
  stepsIris: string[];
  history: PatientHistoryEntry[];
  externalId: string;
  effectiveExitDate: Date;
  userValidationAttempts: number;
}

export class Patient extends User implements PatientInterface {
  // public socialSecurityNumber: string = null;
  public birthDate: string = null;
  public address: string = null;
  public zip: string = null;
  public city: string = null;
  // public generalPractitioner: string = null;
  public zone: string = null;
  public flowEnabled: boolean = null;
  public acceptedCgv: boolean = null;
  public errors: PatientErrorField[] = null;
  public orientationStatus: PatientStatus = null;
  public exitStatus: ExitStatus = null;
  public exitType: ExitType = null;
  public exitDestinationId: string = null;
  public lastInfo: Info = null;
  public lastInfoIri: string = null;
  public contacts?: Contact[] = null;
  public contactsIris: string[] = null;
  public steps?: PatientStep[] = null;
  public stepsIris: string[] = null;
  public history: PatientHistoryEntry[] = null;
  public externalId: string = null;
  public effectiveExitDate: Date = null;
  public userValidationAttempts: number = null;

  public static override getEntityName(): 'patient' {
    return 'patient';
  }

  // public get arrivedSince(): number {
  //   const timeDiffInSeconds =  Math.abs(new Date().getTime() - new Date(this.createdAt).getTime()) / 1000;
  //   return timeDiffInSeconds / 3600;
  // }

  public override fromJson(jsonData: Record<string, unknown>): this {
    super.fromJson(jsonData);
    if (typeof this.createdAt === 'string') {
      this.createdAt = new Date(this.createdAt);
    }
    if (typeof this.effectiveExitDate === 'string') {
      this.effectiveExitDate = new Date(this.effectiveExitDate);
    }
    if (this.steps) {
      this.steps = EntityBuilder.buildMany(PatientStep, this.steps); // FIXME: see fixme in users.selectors.ts
      this.steps?.sort((ps1, ps2) =>
        // STEP_IDS.indexOf(ps1.step?.id) - STEP_IDS.indexOf(ps2.step?.id),
        ps1.id.localeCompare(ps2.id),
      );
    }
    this.history = (this.history ?? []).map(historyEntry => ({
      ...historyEntry,
      date: typeof historyEntry.date === 'string' ? new Date(historyEntry.date) : historyEntry.date,
    }));

    return this;
  }
}

// ------------------------------------------------------------
// CONTACT
// ------------------------------------------------------------

export interface ContactInterface extends UserInterface {
  affiliateLink: string;
  flowEnabled: boolean;
  trusted: boolean;
  emergencyContact: boolean;
  enabled: boolean;
  patients: PatientInterface[];
  patientsIris: string[];
  disabledText: string;
  deletedBy: PatientInterface | HealthProInterface | ContactInterface;
  userValidationAttempts: number;
  accessTokenExist: boolean;
  createdByInterop: boolean;
  differentOfInterop: boolean;
  invitedBy: PatientInterface | HealthProInterface;
}

export class Contact extends User implements ContactInterface {
  public affiliateLink: string = null;
  public flowEnabled: boolean = null;
  public trusted: boolean = null;
  public emergencyContact: boolean = null;
  public enabled: boolean = null;
  public patients: Patient[] = null;
  public patientsIris: string[] = null;
  public disabledText: string = null;
  public deletedBy: Patient | HealthPro | Contact = null;
  public userValidationAttempts: number = null;
  public accessTokenExist: boolean = null;
  public createdByInterop: boolean = null;
  public differentOfInterop: boolean = null;
  public invitedBy: Patient | HealthPro = null;

  // public accountCreationToken?: string; // This should never be serialized

  public static override getEntityName(): 'contact' {
    return 'contact';
  }

  public override fromJson(jsonData: Record<string, unknown>): this {
    super.fromJson(jsonData);
    if (typeof this.activatedAt === 'string') {
      this.activatedAt = new Date(this.activatedAt);
    }
    return this;
  }

  public getPatientFullName(): string {
    return this.patients[0]?.firstName + ' ' + this.patients[0]?.lastName;
  }
}

export const isPatientEighteen = (): UnaryFunction<Observable<User>, Observable<boolean>> => pipe(
  filter(Boolean),
  map(user => {
    if (!(user instanceof Patient)) {
      throw new Error('This page should only be accessible by Patients');
    }
    return user; // Now properly typed as Patient
  }),
  map(patient => differenceInYears(new Date(), new Date(patient.birthDate)) >= 18),
);


// ------------------------------------------------------------
// HEALTH PRO
// ------------------------------------------------------------

export interface HealthProInterface extends UserInterface {
  function: string;
  enabled: boolean;
}

export class HealthPro extends User implements HealthProInterface {
  public function: string = null;
  public enabled: boolean = null;

  public static override getEntityName(): 'health_pro' {
    return 'health_pro';
  }
}

export class Admin extends User implements HealthProInterface {
  public function: string = null;
  public enabled: boolean = null;

  public static override getEntityName(): 'admin' {
    return 'admin';
  }
}
