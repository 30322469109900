import {createAction, props} from '@ngrx/store';


export const addIdInformation = createAction(
  '[Feed] Add Id Information',
  props<{id: string}>(),
);

export const addIdModalInformation = createAction(
  '[Feed] Add Id Modal Information',
  props<{id: string}>(),
);

export const addFeedItemId = createAction(
  '[Feed] Add Feed Item Id',
  props<{id: string}>(),
);
