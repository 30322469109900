import {createReducer, on} from '@ngrx/store';
import {PatientInterface} from '../../+state/users/users.models';
import {createContact, createContactFailure, createContactSuccess, deleteContact, deleteContactFailure, deleteContactSuccess, deletePatient, deletePatientFailure, deletePatientSuccess, getAllPatients, loadPatientByID, loadPatientByIDFailure, loadPatientByIDSuccess, loadPatientsFailure, loadPatientsSuccess, patchContact, patchContactFailure, patchContactSuccess} from './patients.actions';


export interface PatientsState {
  patients: PatientInterface[];
  loading: boolean;
  error: any;
}

export const initialPatientState: PatientsState= {
  patients: [],
  loading: false,
  error: null,
};

export const patientsReducer = createReducer(
  initialPatientState,

  on(getAllPatients, (state, {loader}) => ({
    ...state,
    loading: loader,
    error: null,
  })),

  on(loadPatientsSuccess, (state, {patients}) => ({
    ...state,
    patients,
    loading: false,
  })),

  on(loadPatientsFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  on(loadPatientByID, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),

  on(loadPatientByIDSuccess, (state, {patient}) => ({
    ...state,
    patients: state.patients.map((existingPatient: PatientInterface) =>
      existingPatient.id === patient.id ? patient : existingPatient,
    ),
    loading: false,
  })),

  on(loadPatientByIDFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  on(deletePatient, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),

  on(deletePatientSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(deletePatientFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),

  on(patchContact, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(patchContactSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(patchContactFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  
  on(createContact, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(createContactSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(createContactFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
  
  on(deleteContact, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(deleteContactSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  
  on(deleteContactFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error,
  })),
);