import {Injectable} from '@angular/core';
import {ApiClient, ToastService} from '@forlabs/api-bridge';
import {TranslocoService} from '@jsverse/transloco';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, map, catchError, of, tap, switchMap, from, Observable} from 'rxjs';
import {PatientService} from '../../infrastructure/patient.service';
import {ContactService} from '../../infrastructure/contact.service';
import {getAllPatients, loadPatientsSuccess, loadPatientsFailure, patchContact, patchContactFailure, loadPatientByID, loadPatientByIDSuccess, loadPatientByIDFailure, deleteContact, deleteContactFailure, createContact, sendContactLink, sendContactLinkFailure, deletePatient, deletePatientSuccess, deletePatientFailure} from './patients.actions';


@Injectable()
export class PatientsEffects {
  private scope: string;

  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private contactService: ContactService,
    private toastService: ToastService,
    private translocoService: TranslocoService,
    private apiClient: ApiClient,
  ) {
    this.scope = 'backoffice';
  }

  public loadPatients$ = createEffect(() => this.actions$.pipe(
    ofType(getAllPatients),
    exhaustMap(() => this.patientService.getPatients()
      .pipe(
        map(patients => {
          return loadPatientsSuccess({patients});
        }),
        catchError((error) => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.toast-load-patients-failure', {error: error},
          ), {color: 'danger'});
          return of(loadPatientsFailure({error}));
        }),
      )),
  ));

  public loadPatientByID$ = createEffect(() => this.actions$.pipe(
    ofType(loadPatientByID),
    exhaustMap((action) => this.patientService.getPatientByID(action.id)
      .pipe(
        map(patient => {
          return loadPatientByIDSuccess({patient});
        }),
        catchError((error) => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.toast-load-patient-failure', {id: action.id, error: error},
          ), {color: 'danger'});
          this.toastService.presentToast(`Error loading patients ${action.id}: ${error}`, {color: 'danger'});
          return of(loadPatientByIDFailure({error}));
        }),
      )),
  ));

  public deletePatient$ = createEffect(() => this.actions$.pipe(
    ofType(deletePatient),
    exhaustMap((action) => this.patientService.deletePatient(action.id)
      .pipe(
        map(() => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.toast-delete-patient-success',
          ));
          return deletePatientSuccess({id: action.id});
        }),
        catchError((error) => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope + '.new-list-patients.toast-delete-patient-failure', {error: error},
          ), {color: 'danger'});
          return of(deletePatientFailure({error}));
        }),
      )),
  ));

  public patchContact$ = createEffect(() => this.actions$.pipe(
    ofType(patchContact),
    exhaustMap((action) => this.contactService.updateContact(action.contact)
      .pipe(
        map(() => {
          if (!action.sendMessage) {
            this.toastService.presentToast(this.translocoService.translate(
              this.scope + '.new-list-patients.info-contact-modal.toast-patch-success',
            ));
            return loadPatientByID({id: action.idPatient});
          } else {
            return sendContactLink({contactId: action.contact['@id'], patientId: action.idPatient});
          }
        }),
        catchError((error) => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.info-contact-modal.toast-patch-failure', {error: error},
          ), {color: 'danger'});
          return of(patchContactFailure({error}));
        }),
      )),
  ));

  public sendContactLink$ = createEffect(() => this.actions$.pipe(
    ofType(sendContactLink),
    exhaustMap((action) => this.sendMessage(action.contactId)
      .pipe(
        map(({message}) => {
          this.toastService.presentToast(message, {duration: 3000});
          return loadPatientByID({id: action.patientId});
        }),
        catchError((error) => {
          this.toastService.displayError(error);
          return of(sendContactLinkFailure());
        }),
      )),
  ));
  
  public deleteContact$ = createEffect(() => this.actions$.pipe(
    ofType(deleteContact),
    exhaustMap((action) => this.contactService.deleteContact(action.contact)
      .pipe(
        map(() => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.info-contact-modal.toast-delete-success',
          ));
          return loadPatientByID({id: action.idPatient});
        }),
        catchError((error) => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.info-contact-modal.toast-delete-failure', {error: error},
          ), {color: 'danger'});
          return of(deleteContactFailure({error}));
        }),
      )),
  ));
  
  public createContact$ = createEffect(() => this.actions$.pipe(
    ofType(createContact),
    exhaustMap((action) => this.contactService.createContact(action.contact, action.patient['@id'])
      .pipe(
        switchMap(contact => {
          const contactsIris: string[] = [contact['@id']];
          action.patient.contacts.forEach((contact) => contactsIris.push(contact['@id']));
          return this.patientService.updateContactsPatient(action.patient.id, contactsIris)
            .pipe(
              map(() => {
                this.toastService.presentToast(this.translocoService.translate(
                  this.scope+'.new-list-patients.create-contact-modal.toast-create-success',
                ));
                return loadPatientByID({id: action.patient.id});
              }),
            );
        }),
        catchError((error) => {
          this.toastService.presentToast(this.translocoService.translate(
            this.scope+'.new-list-patients.create-contact-modal.toast-create-failure', {error: error},
          ), {color: 'danger'});
          return of(patchContactFailure({error}));
        }),
      )),
  ));

  private sendMessage(contactId: string): Observable<{ message: string; }> {
    return this.apiClient.post<{ message: string }>(`${contactId}/new_link`, []).pipe(
      map(response => {
        return response;
      }),
    );
  }
}