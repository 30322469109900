import {createAction, props} from '@ngrx/store';
import {Chart, ChartInterface} from './charts.models';


export const getAllCharts = createAction(
  '[Charts] Get All',
  props<{loading: boolean}>(),
);

export const loadChartsSuccess = createAction(
  '[Charts] Load Charts Success',
  props<{ charts: ChartInterface[], areas_color: string[] }>(),
);

export const loadChartsFailure = createAction(
  '[Charts] Load Charts Failure',
  props<{ error: any }>(),
);

export const createChart = createAction(
  '[Charts] Create',
  props<{ chart: Chart }>(),
);

export const createChartSuccess = createAction(
  '[Charts] Create Chart Success',
  props<{ chart: Chart }>(),
);

export const createChartFailure = createAction(
  '[Charts] Create Chart Failure',
  props<{ error: any }>(),
);

export const updateCharts = createAction(
  '[Charts] Update Charts',
  props<{ charts: Chart[] }>(),
);

export const updateChart = createAction(
  '[Charts] Update',
  props<{ id: string, chart: ChartInterface }>(),
);

export const updateChartSuccess = createAction(
  '[Charts] Update Chart Success',
  props<{ id: string, chart: ChartInterface }>(),
);

export const updateChartFailure = createAction(
  '[Charts] Update Chart Failure',
  props<{ error: any }>(),
);

export const deleteChart = createAction(
  '[Charts] Delete',
  props<{ id: string }>(),
);

export const deleteChartSuccess = createAction(
  '[Charts] Delete Chart Success',
  props<{ id: string }>(),
);

export const deleteChartFailure = createAction(
  '[Charts] Delete Chart Failure',
  props<{ error: any }>(),
);