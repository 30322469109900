import {HttpClient} from '@angular/common/http';
import {inject, Injectable, isDevMode, NgModule} from '@angular/core';
import {provideTransloco, Translation, TranslocoLoader, TranslocoModule} from '@jsverse/transloco';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly http = inject(HttpClient);

  public getTranslation(lang: string): Observable<Translation> {
    // return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
    return this.http.get<Translation>(`./assets/i18n/${lang}.json?v=${new Date().getTime()}`);
  }
}

// Fonction pour obtenir la langue depuis le local storage ou utiliser 'fr' par défaut
function getDefaultLang(): string {
  let navigatorLang = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  navigatorLang = navigatorLang.split('-')[0];
  const storedLang = localStorage.getItem('language');
  const lang = storedLang ? storedLang : navigatorLang;
  const availableLangs = ['fr', 'en', 'es', 'pt'];
  return availableLangs.includes(lang) ? lang : 'fr';
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['fr', 'en', 'es', 'pt'],
        defaultLang: getDefaultLang(),
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {}
