import {Injectable} from '@angular/core';
import {ApiClient} from '@forlabs/api-bridge';
import {map, Observable} from 'rxjs';
import {ContactInterface} from '../+state/users/users.models';
import {CurrentUserService} from './current-user.service';


@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(
    private apiClient: ApiClient,
    private currentUser: CurrentUserService,
  ) {}

  public updateContact(contact: ContactInterface): Observable<ContactInterface> {
    return this.apiClient.patch<ContactInterface>(`${contact['@id']}`, {
      'firstName': contact.firstName,
      'lastName': contact.lastName,
      'affiliateLink': contact.affiliateLink,
      'phone': contact.phone,
      'isDelete': contact.isDelete ?? false,
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }
  
  public createContact(contact: ContactInterface, patientIris: string): Observable<ContactInterface> {
    return this.apiClient.post<ContactInterface>('api/contacts', {
      'emergencyContact': false,
      'flowEnabled': true,
      'enabled': true,
      'trusted': false,
      'firstName': contact.firstName,
      'lastName': contact.lastName,
      'affiliateLink': contact.affiliateLink,
      'phone': contact.phone,
      'isDelete': false,
      'role': 'ROLE_CONTACT',
      'organization': contact.organizationIri,
      'patients': [patientIris],
      'login': contact.firstName + contact.lastName + new Date(),
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }
  
  public deleteContact(contact: ContactInterface): Observable<ContactInterface> {
    let user = null;
    this.currentUser.currentUser$.subscribe(current => user = current);
    return this.apiClient.patch<ContactInterface>(`${contact['@id']}`, {
      'isDelete': true,
      'flowEnabled': false,
      'deletedBy': user['@id'],
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }
}