import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FeedState} from './feed.reducer';


export const selectFeedState = createFeatureSelector<FeedState>('feed');

export const selectInformationsID = createSelector(
  selectFeedState,
  (state: FeedState) => state.idInfos,
);

export const selectModalInformationsID = createSelector(
  selectFeedState,
  (state: FeedState) => state.idModalInfos,
);

export const selectFeedItemsID = createSelector(
  selectFeedState,
  (state: FeedState) => state.feedItemsId,
);
