import {createAction, props} from '@ngrx/store';
import {ContactInterface, PatientInterface} from '../../+state/users/users.models';


export const getAllPatients = createAction(
  '[Patients] Get All',
  props<{ loader: boolean}>(),
);

export const loadPatientsSuccess = createAction(
  '[Patients] Load Patients Success',
  props<{ patients: PatientInterface[] }>(),
);

export const loadPatientsFailure = createAction(
  '[Patients] Load Patients Failure',
  props<{ error: any }>(),
);

export const loadPatientByID = createAction(
  '[Patients] Load Patient',
  props<{ id: string }>(),
);

export const loadPatientByIDSuccess = createAction(
  '[Patients] Load Patient Success',
  props<{ patient: PatientInterface }>(),
);

export const loadPatientByIDFailure = createAction(
  '[Patients] Load Patient Failure',
  props<{ error: any }>(),
);

export const deletePatient = createAction(
  '[Patients] Delete Patient',
  props<{ id: string }>(),
);

export const deletePatientFailure = createAction(
  '[Patients] Delete Patient Failure',
  props<{ error: any }>(),
);

export const deletePatientSuccess = createAction(
  '[Patients] Delete Patient Success',
  props<{ id: string }>(),
);

export const patchContact = createAction(
  '[Contacts] Patch Contacts',
  props<{ contact: ContactInterface, idPatient: string, sendMessage: boolean }>(),
);

export const patchContactSuccess = createAction(
  '[Contacts] Patch Contacts Success',
);

export const patchContactFailure = createAction(
  '[Contacts] Patch Contacts Failure',
  props<{ error: any }>(),
);

export const sendContactLink = createAction(
  '[Contacts] Send Contacts Link',
  props<{ contactId: string, patientId: string }>(),
);

export const sendContactLinkFailure = createAction(
  '[Contacts] Send Contacts Link Failure',
);

export const deleteContact = createAction(
  '[Contacts] Delete Contacts',
  props<{ contact: ContactInterface, idPatient: string }>(),
);

export const deleteContactSuccess = createAction(
  '[Contacts] Delete Contacts Success',
);

export const deleteContactFailure = createAction(
  '[Contacts] Delete Contacts Failure',
  props<{ error: any }>(),
);

export const createContact = createAction(
  '[Contacts] Create Contacts',
  props<{ contact: ContactInterface, patient: PatientInterface }>(),
);

export const createContactSuccess = createAction(
  '[Contacts] Create Contacts Success',
);

export const createContactFailure = createAction(
  '[Contacts] Create Contacts Failure',
  props<{ error: any }>(),
);