import {createFeatureSelector, createSelector} from '@ngrx/store';
import {PatientsState} from './patients.reducer';


export const selectPatientState = createFeatureSelector<PatientsState>('patients');

export const selectPatients = createSelector(
  selectPatientState,
  (state: PatientsState) => state.patients,
);

export const selectPatientById = (id: string) => createSelector(
  selectPatientState,
  (state: PatientsState) => {
    let patientSelected = null;
    state.patients.forEach((patient) => {
      if (patient.id.toString() === id) {
        patientSelected = patient;
      }
    });
    return patientSelected;
  },
);

export const getPatientLoader = createSelector(
  selectPatientState,
  (state: PatientsState) => state.loading,
);